<template>
	<page-container class="index" :page="page">
		<heading-image v-if="page.headingImage" type="large">
			<hero-image>
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p class="hero-title">
						{{ defaults[locale].homepage.headingImageTitle }}
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
				</div>
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>
		</heading-image>

		<main-content>
			<div class="grid main-intro">
				<div class="intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
				<div class="intro-text">
					<h2 class="subtitle">
						{{ page.subtitle }}
					</h2>
					<h1>{{ page.title || page.header }}</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</div>
		</main-content>

		<promoblocks :items="promoblocksData" />

		<section v-if="defaults[locale].homepage.sectionReservationsTitle" class="section-reservations">
			<div class="row">
				<div class="columns column5">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionReservationsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.sectionReservationsTitle }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionReservationsContent" />
				</div>
				<div class="columns column7">
					<div class="form-wrapper">
						<form class="reservation-form">
							<div>
								<input placeholder="Name" type="text" />
							</div>
							<div>
								<input placeholder="Email address" type="text" />
							</div>
							<div>
								<input placeholder="Date" type="text" autocomplete="off" readonly="readonly" />
							</div>
							<div>
								<input placeholder="Phone number" type="text" />
							</div>
							<div>
								<input placeholder="Persons" type="text" />
							</div>
							<div>
								<input placeholder="Time" type="text" />
							</div>
							<div>
								<input type="submit" class="button" value="Make a reservation" />
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>

		<section v-if="defaults[locale].homepage.promotionBackgroundImage" class="section-promotion">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.promotionsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.promotionsHeader }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.promotionsContent" />
				</div>
			</div>
			<picture>
				<source :srcset="defaults[locale].homepage.promotionBackgroundImageWebp" type="image/webp" />
				<source :srcset="defaults[locale].homepage.promotionBackgroundImage" />
				<img class="promotions-image" :src="defaults[locale].homepage.promotionBackgroundImage" />
			</picture>
		</section>

		<contentblocks :items="page.contentblocks" class="section-contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionContentblocksSubtitle }}
						</h2>
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
					</div>
				</div>
			</template>
		</contentblocks>

		<section v-if="defaults[locale].homepage.contactHeader" class="content-section section-contact contact-info">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.contactSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.contactHeader }}
					</h2>
					<div v-parse-links v-html="defaults[locale].homepage.contactContent" />
				</div>
			</div>
			<div class="row">
				<div class="columns column4">
					<p>
						<strong>{{ defaults[locale].website.restaurantName }}</strong
						><br />
						{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}<br />
						{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}<br />
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
							{{ defaults[locale].website.phone }} </a
						><br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon class="icon" icon="fal fa-envelope" size="1x" />
							{{ defaults[locale].website.email }}
						</a>
					</p>
				</div>
				<div class="columns column8">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23176.943159367773!2d4.885642175857353!3d52.367227661410524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c63fb5949a7755%3A0x6600fd4cb7c0af8d!2sAmsterdam!5e0!3m2!1sen!2snl!4v1607954444820!5m2!1sen!2snl"
						width="100%"
						height="350"
						frameborder="0"
						style="border: 0"
						allowfullscreen=""
						aria-hidden="false"
						tabindex="0"
					/>
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 50%;
	display: flex;
	flex-flow: column nowrap;
	color: #fff;
	max-width: 1280px;
	text-align: left;
	z-index: 5;
	left: 20px;
	right: 20px;
	margin: auto;
	transform: translateY(calc(-50% + 100px));
	animation: fade-from-bottom 1.5s ease;

	.hero-title {
		width: 100%;
		font-weight: 700;
		color: #fff;
		font-size: 56px;
		margin-bottom: 10px;
		line-height: normal;
	}

	:deep(> *) {
		max-width: 700px;
		margin: 0 0 10px;
	}
}

.main-intro {
	max-width: 1000px;
	margin: auto;
	grid-template-columns: 5fr 7fr;
	gap: 80px;

	.intro-text {
		padding-top: 70px;
	}
}

.section-reservations {
	padding: 100px 0;
	background-color: var(--primary-background-color);

	.form-wrapper {
		padding: 40px 30px;
		background: var(--secondary-background-color);

		.button {
			width: auto;
			margin: 10px 0 0;
			padding: 15px 30px;
		}

		input,
		select,
		textarea {
			color: #fff;
			width: 100%;
			max-width: 500px;
			padding: 20px;
			border: none;
			box-shadow: 0 0 5px rgba(0 0 0 / 10%);
			font-size: 13px;
			font-family: var(--body-font-family);
		}
	}

	.reservation-form {
		display: flex;
		flex-flow: row wrap;
		gap: 25px;

		> div {
			width: calc(50% - 13px);
		}
	}
}

.section-promotion {
	background: var(--secondary-background-color);
	margin: 0;
	padding: 200px 25px;
	overflow: auto;
	clip-path: inset(0);
	position: relative;

	&::after {
		background: rgb(0 0 0 / 60%);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: '';
	}

	.row {
		position: relative;
		z-index: 10;
		color: #fff;
		overflow: hidden;
		max-width: 800px;
	}

	.promotions-image {
		position: absolute;
		object-fit: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	h2:not(.subtitle) {
		color: #fff;
	}
}

.section-contentblocks {
	.blocks-header {
		margin-bottom: 70px;
	}
}

.section-contact {
	.row:first-child {
		padding-bottom: 50px;
	}
}

@keyframes fade-from-bottom {
	0% {
		opacity: 0;
		transform: translateY(calc(-30% + 100px));
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(calc(-50% + 100px));
	}
}

@media (max-width: 1080px) {
	.hero-content {
		max-width: 650px;
	}

	.main-intro {
		grid-template-columns: 100%;

		.intro-text {
			padding-top: 0;
		}
	}

	.intro-image {
		display: none;
	}
}

@media (max-width: 880px) {
	.section-promotions {
		padding: 125px 25px;
	}

	.promotions-image {
		position: absolute;
	}

	.hero-content {
		font-size: 15px;
		padding: 0 10px;

		.hero-title {
			font-size: 30px;
		}
	}

	.section-reservations .reservation-form > div {
		width: 100%;
	}
}
</style>
